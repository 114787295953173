import type {Instruction} from '@backstage/instructions';
import {getAtPath} from '@backstage/utils/object-helpers';
import {Subject, filter, map} from 'rxjs';
import type {Node, NodeField, NodeInstanceData} from '@backstage/flows';
import {DEAD_END, VALUE_NOT_PRESENT} from '@backstage/flows';
import type {TUnion} from '@sinclair/typebox';
import {
  JSONObject,
  JSONValue,
  isJSONObject,
  isJSONValue,
  SiteVariableLookup,
} from '../../../types';
import {
  createInstructionValidator,
  globalVariable,
  isAboutMeCore,
  isInstruction,
  localVariable,
  queryStructure,
  queryStructureForParents,
  storage,
  type InstructionSchema,
  type InstructionValidateFunction,
} from '../../../helpers';
import {Registry} from '../../../registry';
import {GlobalInstructionSchema} from '../../../schemas/global-instruction-definition';
import {BroadcastFunction, LogFunction} from './node.types';

/**
 * Metadata about the instruction and `about` that triggered the flow.
 * The `about` is converted into multiple useful properties that use a
 * baseball motif since the names are much more concise and intuitive
 * than the camelCased abstract names that were considered.
 */
export type FlowHeaders = {
  /** The instruction that triggered the flow. */
  instruction: Instruction;
  showId: string;
  /**
   * The `about` string that the flow author provided. It can be any valid
   * css selector, as it's used to query the XML `structure`.
   */
  selector: string;
  /**
   * An array of the id css selectors of all the nodes that matched the `about`
   * selector at the time the flow was triggered, whether or not each module
   * triggered the flow.
   * This is provided in case all matching modules need some operation applied.
   * An array is used instead of the original `selector` because 1) it's a common
   * currency usable in `bench` below and some flow nodes, and 2) at the time of designing
   * this type, the XML structure was not mutable, but there may be reason to make some
   * properties mutable in the future. By passing this array to downstream nodes, we give
   * the author the choice of using this stable list or re-running `selector` which may
   * return a different set of ids.
   * `structure` with `selector` in downstream node queries.
   * @example
   * `selector`: "[coreId='someCoreId']" matches two modules.
   * `team`: ["#id1", "#id2"]
   */
  team: string[];
  /**
   * The id selector of the module that triggered this flow. Absent
   * or `null` if it wasn't from a page module but rather a module-like
   * actor such as `Router`
   * */
  batter?: string | null;
  /**
   * `team` minus `batter`. An array of id selectors matching each of the page
   * modules that *could* have triggered this flow but did not. This is useful because
   * so many flows concern "which module triggered this?" and "which modules
   * could have, but didn't?". Especially radio-style toggling among 2+ modules.
   */
  bench: string[];
};

/**
 * The values passed to a Flow Node's converter function.
 *
 * When executing a flow, all information is passed between nodes in a packet
 * style with `headers` and `data` (cf `body` in http) properties, plus other
 * properties defined below.
 */
export type ConverterArgs = {
  /**
   * Information about the instruction that triggered this flow including the
   * instruction itself and ids of the modules that matched the `about` selector.
   */
  headers: FlowHeaders;
  /** Function to log when instructions are received */
  log: LogFunction;
  /** Data passed from the upstream node. Similar to an `http` `body` */
  data: Record<string, unknown>;
  /** Queryable XML representation of the module tree */
  structure: XMLDocument;
  /**
   * The Flow Node in question that defines which converter function is to run at
   * this step of the flow plus the identities and values of inputs, outputs, and fields.
   */
  node: Node<NodeInstanceData>;
  /**
   * The function for broadcasting into the instruction system.
   */
  broadcast: BroadcastFunction;
  /** "dictionary" of SiteVariable keys to values */
  siteVariableLookup: SiteVariableLookup;
};

type ConverterFn = (
  converterArgs: ConverterArgs
) => void | Record<string, unknown> | typeof DEAD_END;

/**
 * Gets a handler function immediately for `id`. If there is not a handler
 * function for `id` a temporary one resulting in `DEAD_END` is returned.
 * @param id of the function to retrieve.
 * @returns the existing registered function if it exists or a temporary one
 * resulting in `DEAD_END` if it does not.
 */
export function getFunc(id: string): ConverterFn;
/**
 * Gets a handler function for `id` waiting until one is registered if one does
 * not already exist.
 * @param id of the function to retrieve.
 * @returns the function registered for `id`.
 */
export function getFunc(id: string, mode: 'async'): Promise<ConverterFn>;
export function getFunc(
  id: string,
  mode: 'async' | 'sync' = 'sync'
): ConverterFn | Promise<ConverterFn> {
  if (mode === 'sync') {
    return getFuncSync(id);
  }
  return new Promise<ConverterFn>((resolve) => {
    const func = funcs[id];
    if (func) {
      resolve(func);
    } else {
      const subscription = funcsBus
        .asObservable()
        .pipe(
          filter((ev) => ev.type === 'register' && ev.detail.key === id),
          map((ev) => funcs[ev.detail.key])
        )
        .subscribe((fn) => {
          if (typeof fn === 'function') {
            resolve(fn);
            subscription.unsubscribe();
          }
        });
    }
  });
}

/**
 * Gets a handler function immediately for `id`. If there is not a handler
 * function for `id` a temporary one resulting in `DEAD_END` is returned.
 * @param id of the function to retrieve.
 * @returns the existing registered function if it exists or a temporary one
 * resulting in `DEAD_END` if it does not.
 */
function getFuncSync(id: string): ConverterFn {
  const fn = funcs[id];
  if (typeof fn !== 'undefined') {
    return fn;
  } else {
    console.error({tag: 'getFunc', msg: '❌ NOT FOUND for', id});
    return () => DEAD_END;
  }
}

interface GetDataValueOptions {
  /** The flow data passed along in the process */
  data: Record<string, unknown>;
  /** The key to lookup in `data` */
  key: string;
}

/**
 * Lookup a property in an object with unknown contents replacing
 * `VALUE_NOT_PRESENT` with `undefined`.
 */
function getDataValue(options: GetDataValueOptions): undefined | JSONValue {
  const {data, key} = options;
  const value = data[key];
  if (value === VALUE_NOT_PRESENT) {
    return undefined;
  }

  return value as JSONValue;
}

interface GetFieldValueOptions {
  /** `Node` containing field data for lookup */
  node: Node<NodeInstanceData>;
  /** The slug of the `NodeField` to find */
  slug: string;
  /** The value used to replace `VALUE_NOT_PRESENT` */
  fallback: string;
}

/**
 * Lookup a field's value in `NodeInstanceData` replacing `VALUE_NOT_PRESENT`
 * with the given `fallback`.
 */
function getFieldValue(options: GetFieldValueOptions): string {
  const {node, fallback, slug} = options;
  const field = node.data?.fields?.find((x) => x.slug === slug);
  const value = extractFieldValue(field);
  if (value === VALUE_NOT_PRESENT) {
    return fallback;
  } else {
    return value ?? fallback;
  }
}

/**
 * Get the value from a `NodeField` replacing an empty string with
 * `VALUE_NOT_PRESENT`
 */
function extractFieldValue(field?: NodeField): NodeField['value'] {
  if (typeof field?.value === 'string' && field?.value === '') {
    return VALUE_NOT_PRESENT;
  } else {
    return field?.value;
  }
}

/**
 * Compare the value of each field in the given list returning true if each
 * field has a matching value in the instruction's meta.
 * @private exported for tests
 */
export function isFieldMatch(
  node: ConverterArgs['node'],
  instruction: Instruction
): boolean {
  const instructionMeta = instruction.meta;
  const nodeFields = node.data?.fields ?? [];
  const result = nodeFields
    .filter((field) => field.slug !== 'about')
    .reduce((isMatch, field) => {
      const {slug} = field;
      const value = extractFieldValue(field);
      if (typeof slug === 'undefined') {
        return isMatch;
      } else if (typeof value === 'string') {
        return isMatch && (value === '*' || value === instructionMeta[slug]);
      } else {
        return isMatch;
      }
    }, true);
  return result;
}

const createFuncs = (
  instructions: TUnion<InstructionSchema[]>
): Record<string, ConverterFn> => {
  const isValidInstruction = createInstructionValidator(instructions);
  return instructions.anyOf.reduce<Record<string, ConverterFn>>((memo, s) => {
    const topic = s.properties.topic.const;
    const which = s.properties.which.const;
    const namespace = topic.split(':').slice(0, -1).join(':') ?? 'unknown';
    const icon = `${ICONS[which] ?? which}${ICONS[namespace] ?? namespace}`;
    if (typeof memo[topic] !== 'undefined') {
      return memo;
    } else if (which === 'publish') {
      memo[topic] = instructionFunction(icon);
    } else if (which === 'subscribe') {
      memo[topic] = instructionSubscriber(icon, topic, isValidInstruction);
    }
    return memo;
  }, {});
};

const instructionFunction =
  (icon: string): ConverterFn =>
  (args): typeof DEAD_END | {instruction: Instruction} => {
    const {node, data, headers, log, structure} = args;
    if (!('instruction' in data) || !isInstruction(data.instruction)) {
      return DEAD_END;
    }
    const instructionMeta = data.instruction.meta;
    const filter = getFieldValue({fallback: '*', node, slug: 'about'});
    const moduleNeedle =
      'about' in instructionMeta && typeof instructionMeta.about === 'string'
        ? instructionMeta.about
        : undefined;

    const result = isAboutMeCore(
      structure,
      filter,
      moduleNeedle,
      node.data?.namespace
    );

    if (result.isMatch && isFieldMatch(node, data.instruction)) {
      headers.selector = filter ?? '*';
      headers.batter = result.moduleNeedle;
      headers.team = result.selectorMatches;
      headers.bench = headers.batter
        ? headers.team.filter((x) => x !== headers.batter)
        : [];

      log(icon, data.instruction);
      return {
        instruction: data.instruction,
      };
    }
    return DEAD_END;
  };

const instructionSubscriber =
  <Schemas extends InstructionSchema[]>(
    icon: string,
    topic: string,
    isValidInstruction: InstructionValidateFunction<Schemas>
  ): ConverterFn =>
  ({data, broadcast, log}) => {
    log(icon, data);
    const metaEntries = Object.entries(data).filter(
      ([, value]) => value !== VALUE_NOT_PRESENT
    );
    const instruction: Instruction = {
      type: topic,
      meta: Object.fromEntries(metaEntries) as JSONObject,
    };

    if (isValidInstruction(instruction)) {
      broadcast(instruction, 'flow');
    } else {
      console.warn(
        'Instruction invalid against schema and failed to broadcast.',
        instruction
      );
    }
  };

const readVariableFunction =
  (kind: 'local' | 'global'): ConverterFn =>
  ({data, headers: {showId}, node}) => {
    const about = getFieldValue({fallback: '__EMPTY__', node, slug: 'about'});
    const myKey = about === '__EMPTY__' ? data.key : about;

    // Do not send to `valueIfNotExists` since a blank key is a "not even wrong" case.
    if (typeof myKey !== 'string') {
      return DEAD_END;
    }
    const key =
      kind === 'local'
        ? localVariable(showId, myKey)
        : globalVariable(showId, myKey);
    const serialized = storage.getItem(key);
    if (typeof serialized !== 'string') {
      return {
        valueIfNotExists: null,
        value: null,
      };
    }
    const value = JSON.parse(serialized);
    return {
      value,
      valueIfExists: value,
    };
  };

/**
 * Register event, to be emitted when a new entry is added to `funcs`.
 */
interface FuncsRegisterEvent {
  type: 'register';
  detail: {
    key: string;
  };
}

/**
 * Splits a string into an array of strings, splitting on comma. This is most useful
 * for strings that can be lists of ids or lists of keys.
 * @param str a string that may be a comma-delimited list of values.
 * @returns an array of the split and trimmed values.
 */
const stringToListArr = (str: string): string[] =>
  str
    .trim()
    .split(/\s*,\s*/)
    .filter((x) => !!x);

const arrToDedupedListString = (arr: string[]): string => {
  return Array.from(new Set(arr.filter((x) => !!x)))
    .sort()
    .join(',');
};

// should we have "time::" and others?
/**
 * The map of prefixes for the different locations for data. Most are simple
 * variables, but `headers` and `meta` are atypical cases.
 */
const prefixes = {
  headers: 'headers::',
  meta: 'meta::',
  global: 'global::',
  local: 'local::',
  site: 'site::',
} as const;

/**
 * Obtains the value at a given path in an object. The `path`, if prefixed,
 * will be used to determine the source of the data. Without a prefix,
 * `edgeInput` will be used as the source.
 * @see prefixes
 */
const getNestedValue = (
  path: string,
  edgeInput: unknown,
  headers: FlowHeaders,
  siteVariableLookup: SiteVariableLookup
): unknown => {
  let value: unknown;
  if (path.startsWith(prefixes.meta)) {
    value = getAtPath(
      headers.instruction.meta || {},
      path.slice(prefixes.meta.length)
    );
  } else if (path.startsWith(prefixes.headers)) {
    value = getAtPath(headers, path.slice(prefixes.headers.length));
  } else if (path.startsWith(prefixes.global)) {
    value = storage.getItem(
      globalVariable(headers.showId, path.slice(prefixes.global.length))
    );
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
  } else if (path.startsWith(prefixes.local)) {
    value = storage.getItem(
      localVariable(headers.showId, path.slice(prefixes.local.length))
    );
    if (typeof value === 'string') {
      value = JSON.parse(value);
    }
  } else if (path.startsWith(prefixes.site)) {
    value = getAtPath(siteVariableLookup, path.slice(prefixes.site.length));
  } else {
    value = getAtPath(edgeInput, path);
  }

  if (
    Array.isArray(value) &&
    // These two are special, as they're stored as arrays but sent as strings.
    // We currently have no other arrays being passed, but this guard leaves
    // open the possibility.
    ('headers::team' === path || 'headers::bench' === path)
  ) {
    return value.join(',');
  }

  return value;
};

export const swapInValues = (
  input: string,
  edgeInput: unknown,
  headers: FlowHeaders,
  siteVariableLookup: SiteVariableLookup
): unknown => {
  // There's nothing to look up, so just return the input.
  if (!input.includes('{{')) {
    return input;
  }

  // If the entire input is a variable, resolve. It's reasonable for the output
  // to be any JSONValue.
  if (/^\{\{([^}]+?)\}\}$/.test(input)) {
    const value = getNestedValue(
      input.slice(2, -2),
      edgeInput,
      headers,
      siteVariableLookup
    );
    return value === undefined ? DEAD_END : value;
  }

  // The input has more than simply a single variable (multiple variables,
  // adornment, etc), so we need to interpolate and return a string.
  let isDeadEnd = false;

  const output = input.replace(/\{\{(.+?)\}\}/g, (_, $1: string) => {
    const value = getNestedValue($1, edgeInput, headers, siteVariableLookup);
    if (value === undefined) {
      isDeadEnd = true;
    }
    return `${value}`;
  });

  return isDeadEnd ? DEAD_END : output;
};

/** Union of change notifications published on the `funcsBus`. */
type FuncsEvent = FuncsRegisterEvent;

const funcsBus = new Subject<FuncsEvent>();

const ICONS: Record<string, string> = {
  AccessCode: '🔑',
  Audio: '🎶',
  Button: '🚰',
  Chat: '💬',
  Countdown: '⏱',
  Image: '🖼',
  Intercom: '🎙',
  Router: '🚌',
  Video: '📽',
  publish: '🕸',
  subscribe: '💥',
  'Global:parent': '🪟',
};

export const funcs: Record<string, ConverterFn> = {
  ...createFuncs(GlobalInstructionSchema),
  'about:extract': ({headers}) => {
    return {
      about: headers.selector,
      batter: headers.batter,
      bench: headers.bench.join(','),
      team: headers.team.join(','),
    };
  },

  'about:parent': ({data, structure}) => {
    const input = getDataValue({data, key: 'input'});
    return typeof input !== 'string'
      ? DEAD_END
      : {output: queryStructureForParents(structure, `${input}`).join(',')};
  },

  'about:random': ({data, structure}) => {
    const abouts = getDataValue({data, key: 'abouts'});
    if (typeof abouts !== 'string') {
      return {error: 'Invalid input'};
    }
    const aboutIds = queryStructure(structure, `${abouts}`);
    if (aboutIds.length === 0) {
      return {error: 'No ids matched the selector'};
    }
    return {
      output: aboutIds.at(Math.floor(Math.random() * aboutIds.length)),
    };
  },

  'about:remove': ({data, structure}) => {
    const abouts = getDataValue({data, key: 'abouts'});
    const toRemove = getDataValue({data, key: 'toRemove'});
    if (typeof abouts !== 'string' || typeof toRemove !== 'string') {
      return DEAD_END;
    }
    const aboutIds = queryStructure(structure, `${abouts}`);
    const toRemoveIds = queryStructure(structure, `${toRemove}`);
    return {
      output: aboutIds.filter((x) => !toRemoveIds.includes(x)).join(','),
    };
  },

  'about:add': ({data, structure}) => {
    const abouts = getDataValue({data, key: 'abouts'});
    const toAdd = getDataValue({data, key: 'toAdd'});
    let selector: string = typeof abouts === 'string' ? abouts : '';
    if (typeof toAdd === 'string' && toAdd.length > 0) {
      selector = `${abouts}, ${toAdd}`;
    }
    return {
      output: queryStructure(structure, selector).join(','),
    };
  },

  'about:attributes': ({data, structure}) => {
    const input = getDataValue({data, key: 'input'});
    if (typeof input === 'string' && input.length > 0) {
      const elements = structure.querySelectorAll(input);
      if (elements.length > 0) {
        if (elements.length > 1) {
          console.warn(
            'FLOW ERROR: More than one element found for `about:attributes`. Using only the first one.'
          );
        }
        const element = elements[0];
        if (element) {
          return {
            attributes: element
              .getAttributeNames()
              .reduce<Record<string, string>>((a, attribute) => {
                const value = element.getAttribute(attribute);
                if (typeof value === 'string') {
                  a[attribute] = value;
                }
                return a;
              }, {}),
          };
        }
      }
    }
    return {notFound: input};
  },

  'about:toIds': ({data, structure}) => {
    const abouts = getDataValue({data, key: 'abouts'});
    if (typeof abouts !== 'string') {
      return DEAD_END;
    }

    return {
      output: queryStructure(structure, abouts).join(','),
    };
  },

  adornString: ({node, data}) => {
    const input = getDataValue({data, key: 'input'});
    const format = getFieldValue({fallback: '__EMPTY__', node, slug: 'format'});
    // If the fallback value (indicating empty field) return from `input`
    if (format === '__EMPTY__') {
      return {value: input};
    }
    return {
      value: format.replace(
        /\${(input\d)}/g,
        (_, x): string => `${data[x] ?? ''}`
      ),
    };
  },
  consoleLog: ({node, data, headers, log}) => {
    const input = getDataValue({data, key: 'input'});
    const label = getFieldValue({fallback: '', node, slug: 'label'});
    log('🪵', `${label || node.id.split('-').shift()}`, {
      input,
      headers,
    });
  },

  compareTime: ({data}) => {
    if (
      typeof data.inputTime !== 'string' ||
      typeof data.comparisonTime !== 'string'
    ) {
      return DEAD_END;
    }

    const {inputTime, comparisonTime} = data;

    const inputTimeDate = new Date(inputTime).getTime();
    const comparisonTimeDate = new Date(comparisonTime).getTime();

    let outputKey: string;

    if (inputTimeDate > comparisonTimeDate) {
      outputKey = 'isAfter';
    } else if (inputTimeDate === comparisonTimeDate) {
      outputKey = 'isSame';
    } else if (inputTimeDate < comparisonTimeDate) {
      outputKey = 'isBefore';
    } else {
      return DEAD_END;
    }

    return {
      [outputKey]: data.comparisonTime,
    };
  },

  destructure: ({data}) => {
    if (typeof data.path !== 'string' || !isJSONValue(data.object)) {
      return DEAD_END;
    }
    const ifAbsent = VALUE_NOT_PRESENT;
    // Very naïve computation here, just for the example.
    const object = isJSONObject(data.object) ? data.object : {};
    const path = getDataValue({data, key: 'path'}) ?? '';
    if (typeof path !== 'string') {
      return {ifAbsent};
    }

    const value = getAtPath(object, path);
    if (value === undefined) {
      return {ifAbsent};
    } else {
      return {value};
    }
  },

  emitInstruction: ({node, data, broadcast, log}) => {
    log('💥📣', data.instructionToEmit);
    if (
      !(
        'instructionToEmit' in data &&
        typeof data.instructionToEmit === 'string'
      )
    ) {
      return DEAD_END;
    }
    const instruction: unknown = JSON.parse(data.instructionToEmit);
    if (isInstruction(instruction)) {
      broadcast(instruction, 'flow');
    } else {
      console.error('Invalid instruction at', node);
    }
  },
  'CustomInstruction:broadcast': ({data, broadcast}) => {
    if (typeof data.topic === 'string') {
      const meta: JSONObject = {
        topic: data.topic,
      };
      const param1 = getDataValue({data, key: 'param1'});
      const param2 = getDataValue({data, key: 'param2'});
      if (param1) {
        meta.param1 = param1;
      }
      if (param2) {
        meta.param2 = param2;
      }
      broadcast({type: 'CustomInstruction:on-broadcast', meta}, 'flow');
    }
  },
  'CustomInstruction:on-broadcast': instructionFunction('🕸📐'),

  equals: ({data}) => {
    const key = data.a == data.b ? 'aIfEqual' : 'aIfNotEqual';
    return {[key]: data.a};
  },

  mediaQuery: ({data}) => {
    if (typeof window === 'undefined') {
      return DEAD_END;
    }

    const mq = getDataValue({data, key: 'mediaQuery'}) ?? '';
    if (typeof mq !== 'string') {
      return DEAD_END;
    }

    const {matches} = window.matchMedia(mq);
    const key = matches ? 'isMatch' : 'notMatch';
    return {
      [key]: data.input,
      value: matches.toString(),
    };
  },

  randomInteger: ({data}) => {
    const min = parseInt(`${data.min}`, 10);
    const max = parseInt(`${data.max}`, 10);
    if (isNaN(min) || isNaN(max)) {
      return {error: 'Invalid input'};
    }
    if (min > max) {
      return {error: 'Min is greater than max'};
    }
    return {
      value: Math.floor(Math.random() * (max - min + 1)) + min,
    };
  },

  supportedInputs: () => {
    const vals: ('mouse' | 'touch')[] = [];

    if (window.matchMedia('(pointer: fine)').matches) {
      vals.push('mouse');
    }

    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
      vals.push('touch');
    }

    // `keyboard` is absent from this check because it's irrelevant or not detectable:
    // Browsers on all devices we support have a keyboard for text input, even
    // if it's a virtual keyboard. There is no reasonably reliable way to detect
    // if a device has a physical, ever-present keyboard for hotkeys, game
    // controlling, etc.

    return {
      output: vals.join(','),
    };
  },

  stringComparison: ({data}) => {
    if (
      typeof data.inputString !== 'string' ||
      typeof data.mode !== 'string' ||
      typeof data.caseSensitivity !== 'string' ||
      typeof data.comparator !== 'string'
    ) {
      return DEAD_END;
    }

    const {inputString, mode, comparator, caseSensitivity} = data;
    const isCaseSensitive = caseSensitivity === 'Case Sensitive';

    const matcher = (x: string): boolean | 'ERROR' => {
      const hay = isCaseSensitive ? x : x.toLowerCase();
      const needle = isCaseSensitive ? comparator : comparator.toLowerCase();

      switch (mode) {
        case 'Contains':
          return hay.includes(needle);
        case 'Starts With':
          return hay.startsWith(needle);
        case 'Ends With':
          return hay.endsWith(needle);
        case 'Equals':
          return hay === needle;
        default:
          return false;
      }
    };

    const matchResult = matcher(inputString);

    if (matchResult === 'ERROR') {
      return {error: inputString};
    }
    return {[matchResult ? 'ifTrue' : 'ifFalse']: inputString};
  },

  staticString: ({data}) => {
    return {value: data.value};
  },

  supplantWithString: ({data}) => {
    return {
      value: data.valueToOutput,
    };
  },

  'list:add': ({data}) => {
    if (typeof data.list !== 'string' || typeof data.toAdd !== 'string') {
      return DEAD_END;
    }
    return {
      output: arrToDedupedListString(
        stringToListArr(`${data.list}, ${data.toAdd}`)
      ),
    };
  },

  'list:contains': ({data}) => {
    if (typeof data.list !== 'string' || typeof data.probe !== 'string') {
      return DEAD_END;
    }
    const probe = data.probe.toLowerCase();
    const list = stringToListArr(data.list.toLowerCase());
    const key = list.includes(probe) ? 'if' : 'else';
    return {[key]: data.list};
  },

  'list:remove': ({data}) => {
    if (typeof data.list !== 'string' || typeof data.toRemove !== 'string') {
      return DEAD_END;
    }
    const list = stringToListArr(data.list);
    const toRemove = stringToListArr(data.toRemove);
    const output = list.filter((x) => !toRemove.includes(x));
    return {
      output: arrToDedupedListString(output),
    };
  },

  keyValueLookup: ({node, data, headers, siteVariableLookup}) => {
    if (typeof data.key !== 'string' && data.keyType !== 'Random Key') {
      return DEAD_END;
    }

    const lookupRaw = getFieldValue({fallback: '', node, slug: 'lookup'});

    const swap = (x: string): string =>
      // edgeInput is hard-coded to `undefined` because converter functions don't
      // receive it directly. It's processed into `data.key` if it is relevant at all.
      String(swapInValues(x, undefined, headers, siteVariableLookup) ?? '');

    const lookup = lookupRaw
      .split(/(\r?\n)+/)
      .map((x) => x.trim())
      .filter((x) => !!x)
      .map((x) => x.split(/\s*,\s*/));

    const keyToUse =
      data.keyType != 'Random Key'
        ? data.key
        : swap(lookup[Math.floor(Math.random() * lookup.length)]?.[0] ?? '');

    for (let i = 0; i < lookup.length; i++) {
      const items = lookup[i] ?? [];
      if (swap(items[0] ?? '') === keyToUse) {
        const others = lookup.reduce<string[][]>(
          (a, c, index) => {
            if (index === i) {
              return a;
            }
            a[1]?.push(swap(c[1] ?? ''));
            a[2]?.push(swap(c[2] ?? ''));
            a[3]?.push(swap(c[3] ?? ''));
            return a;
          },
          [[], [], [], []]
        );

        return {
          value1: swap(items[1] ?? ''),
          value2: swap(items[2] ?? ''),
          value3: swap(items[3] ?? ''),
          others1: arrToDedupedListString(others[1] ?? []),
          others2: arrToDedupedListString(others[2] ?? []),
          others3: arrToDedupedListString(others[3] ?? []),
        };
      }
    }
    return {
      error: data.key,
    };
  },

  currentTime: () => {
    const date = new Date();

    return {
      timestamp: date.toISOString(),
    };
  },

  readVariable: readVariableFunction('global'),

  readLocalVariable: readVariableFunction('local'),

  'Global:variable:on-set': instructionFunction('🕸🧩'),

  'variable:set': ({data, broadcast}) => {
    const {value, key} = data;

    if (typeof key === 'string' && typeof value === 'string') {
      const keys = stringToListArr(key);
      const assignments = keys.reduce<Record<string, JSONValue>>((a, c) => {
        a[c] = value;
        return a;
      }, {});

      broadcast({type: 'variable:set', meta: assignments}, 'flow');
    } else {
      return DEAD_END;
    }
  },

  'variable:removeAllLocal': ({headers}) => {
    const variablePrefix = localVariable(headers.showId);
    let index = storage.length;

    // Since we must access by index, remove in reverse order.
    while (--index >= 0) {
      const key = storage.key(index);
      if (key && key.startsWith(variablePrefix)) {
        storage.removeItem(key);
      }
    }

    return {
      output: '',
    };
  },

  'variable:unset': ({data, broadcast}) => {
    const {key} = data;

    if (typeof key === 'string') {
      const keys = stringToListArr(key);
      const assignments = keys.reduce<Record<string, JSONValue>>((a, c) => {
        a[c] = null;
        return a;
      }, {});

      broadcast({type: 'variable:unset', meta: assignments}, 'flow');
    } else {
      return DEAD_END;
    }
  },

  'Global:api:post': ({data}) => {
    const body = getDataValue({data, key: 'body'});
    const url = getDataValue({data, key: 'url'});
    const headers = getDataValue({data, key: 'headers'});

    if (!url || typeof url !== 'string') return DEAD_END;
    if (headers !== undefined && typeof headers !== 'string') return DEAD_END;
    if (body !== undefined && typeof body !== 'string') return DEAD_END;

    const parsedHeaders = headers ? JSON.parse(headers) : {};

    fetch(url, {
      method: 'POST',
      mode: 'cors',
      headers: parsedHeaders,
      body,
    });
  },
};

Registry.on('register', (c) => {
  if (typeof c.instructions === 'undefined') {
    // nothing to do
    return;
  }
  const moduleFuncs = createFuncs(c.instructions);
  for (const [topic, converterFn] of Object.entries(moduleFuncs)) {
    if (typeof funcs[topic] !== 'undefined') {
      // do not overwrite existing coverter functions
      continue;
    } else {
      funcs[topic] = converterFn;
    }
    // Notify if we changed `funcs`
    if (typeof funcs[topic] !== 'undefined') {
      funcsBus.next({type: 'register', detail: {key: topic}});
    }
  }
});
